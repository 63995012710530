import React from "react"
import * as styles from "./ProblemsSolutions.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ProblemsSolutions = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.problemsSolutionsAppWork}>
        <Container>
          <Row>
            <Col lg={6} md={12} xs={12} sm={12} className={styles.problemsCol}>
              <h2 className={styles.problemsHeading}>Challenges They Faced</h2>
              <p className={styles.problemsDescription}>
                Training of all the technicians for the tech app and design
                guide for better user experience.
              </p>
            </Col>
            <Col className={styles.solutionsCol}>
              <h2
                className={styles.solutionHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.solutionSubHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProblemsSolutions
