import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
const Banner = ({ strapiData }) => {
  return (
    <div className={styles.portfolioAppWorkBanner}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className={styles.Heading}>
            <h1
              className={styles.bannerHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
