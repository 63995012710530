import { Link } from "gatsby"
import React from "react"
import * as styles from "./ResultMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ResultMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.liveLinkBannerAppworkMobile}>
        <Container className={styles.containerLiveLinkResultMobile}>
          <Row>
            <Col>
              <h2
                className={styles.liveLinkHeadingResult}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.liveLinkDescriptionResult}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={`${styles.bannerBtnResultAppwork}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to="https://appworkco.com/"
                    target="_blank"
                    className={styles.result_mobile_app_Work}
                    rel="nofollow"
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResultMobile
