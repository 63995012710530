import React from "react"
import * as styles from "./ObjectiveMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ObjectiveMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.objectiveAppworkMobile}>
        <Container className={styles.containerAppWorkMobile}>
          <Row>
            <Col>
              <h2
                className={styles.objectiveHeadingMobile}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.objectiveDescriptionMobile}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObjectiveMobile
