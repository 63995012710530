import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/appwork/Banner"
import Bottombanner from "../../components/appwork/Bottombanner"
import Objective from "../../components/appwork/Objective"
import ProblemsSolutions from "../../components/appwork/ProblemsSolutions"
import TechStacksTheraForge from "../../components/appwork/TechStacksTheraForge"
import Result from "../../components/appwork/Result"
import ObjectiveMobile from "../../components/appwork/ObjectiveMobile"
import ResultMobile from "../../components/appwork/ResultMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      />
    </>
  )
}
const Appwork = ({ data }) => {
  const heroSection = data?.strapiPage?.sections[0]
  const theraforge = data?.strapiPage?.sections[1]
  const solutions = data?.strapiPage?.sections[2]
  const tech = data?.strapiPage?.sections[3]
  const result = data?.strapiPage?.sections[4]
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout bgChanged={false}>
      <Banner strapiData={heroSection} />
      <Bottombanner />
      {!isTablet ? (
        <Objective strapiData={theraforge} />
      ) : (
        <ObjectiveMobile strapiData={theraforge} />
      )}
      <ProblemsSolutions strapiData={solutions} />
      <TechStacksTheraForge strapiData={tech} />
      {!isTablet ? (
        <Result strapiData={result} />
      ) : (
        <ResultMobile strapiData={result} />
      )}

      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "appwork" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Appwork
