// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksTheraForge-module--cardBg--958d7";
export var cards = "TechStacksTheraForge-module--cards--d0d86";
export var description = "TechStacksTheraForge-module--description--ec52a";
export var heading = "TechStacksTheraForge-module--heading--adff7";
export var infaHeading = "TechStacksTheraForge-module--infaHeading--3a89e";
export var nav = "TechStacksTheraForge-module--nav--76ce1";
export var navItem = "TechStacksTheraForge-module--nav-item--6083b";
export var navbarBlock = "TechStacksTheraForge-module--navbarBlock--99fe3";
export var tabData = "TechStacksTheraForge-module--tabData--a2fbf";
export var tech = "TechStacksTheraForge-module--tech--113b9";
export var techIconssCard = "TechStacksTheraForge-module--techIconssCard--5bd83";
export var techInfrastructures = "TechStacksTheraForge-module--techInfrastructures--77ee1";
export var techStackImg = "TechStacksTheraForge-module--techStackImg--fda6b";
export var techStacksAppWork = "TechStacksTheraForge-module--techStacksAppWork--5cca4";
export var techStacksCardsContent = "TechStacksTheraForge-module--techStacksCardsContent--2f8ce";
export var techStacksIcon = "TechStacksTheraForge-module--techStacksIcon--f0876";
export var technologyStacksIcon = "TechStacksTheraForge-module--technologyStacksIcon--c6a7d";