import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechStacksTheraForge.module.scss"
import Row from "react-bootstrap/Row"

const data = [
  {
    name: "Elixir",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Elixir_c097615cfc.svg",
  },
  {
    name: "PostgreSQL",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_1b8b78d0c5.svg",
  },
  {
    name: "React",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_bcdb2524c0.svg",
  },
  {
    name: "React-native",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_fe007cea1b.svg",
  },
]

const TechStacksTheraForge = ({ strapiData }) => {
  return (
    <div className="TechStacksTabs">
      <div className={styles.techStacksAppWork}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div className={styles.techStacksCardsContent}>
            <Row className={styles.techStacksIcon}>
              {data &&
                data?.map((e, i) => (
                  <div className={` ${styles.techIconssCard}`} key={i}>
                    <div className={styles.technologyStacksIcon}>
                      <div className={styles.techStackImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.icon}
                          alt={e?.name}
                        />
                      </div>
                      <h3>{e?.name}</h3>
                    </div>
                  </div>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default TechStacksTheraForge
